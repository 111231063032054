body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
}

.app__container {
  width: 100%;
  display: flex;
  justify-content: center;
  overflow: auto;
  height: 100vh;
  /*height: calc(100vh - 40px);*/
  min-height: calc(100vh - 40px);
}

h1 {
  font-size: 44px;
  font-weight: 500;
  text-transform: uppercase;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header__logo {
  font-size: 30px;
  font-weight: bolder;
}

.btn {
  font-size: 18px;
  font-weight: 600;
  border: none;
  border-radius: 29px;
  padding: 10px 25px;
  color: #fff;
  cursor: pointer;
  background: #7fa5c9;
}

.btn.small {
  font-size: 16px;
}

#phaser-container {
  width: 100%;
}

#phaser-container canvas {
  display: block;
  margin: 0;
  width: 100% !important;
  height: 95vh;
  border: 1px solid #dedede;
}

/* custom.css */
.ant-btn,
.ant-input,
.ant-select-selector,
.ant-modal-content {
  border-radius: 15px !important;
}

.ant-modal-header {
  border-radius: 15px 15px 0 0 !important;
}

.ant-form-item-label {
  font-weight: 500;
}

.anticon.ant-notification-notice-icon-info {
  position: absolute;
  bottom: 20px !important;
}
.ant-checkbox-wrapper {
  font-weight: 500;
}
/**/
